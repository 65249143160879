<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="confirm-action-pop_up-block pop_up-container">
    <div class="pop_up-wrap">
      <div class="confirm-action-pop_up-container">
        <h4>Are you sure?</h4>
        <p class="sup-text">{{description}}</p>
        <div class="confirm-action-pop_up-buttons">
          <button class="basic_green-btn" @click="confirmAction()">Confirm</button>
          <button class="reset_btn" @click="$emit('discardAction')">Discard</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkWindowWidth from "@/mixins/checkWindowWidth"

export default {
  name: "ConfirmActionPopUp",
  props: ['description'],
  mixins:[
    checkWindowWidth,
  ],
  methods:{
    async confirmAction(){
      this.preloaderState = true
      await this.$emit('confirmAction')
    },
  }
}
</script>
