<template>
  <user-dashboard-layout>
    <div class="admin-users-container">

      <div class="portfolios-filter admin-users-filter">
        <AdminUserFilter v-if="showTableFilter" :key="filterComponentKey" @filterItems="filterUserItems()"/>

        <div class="admin-users-new">
          <button @click="showCreateUserPopUp()" class="basic_green-btn">New</button>

          <AdminCreateUserInfoPopUp
            v-if="createUserPopUpState"
            @fetchUserItems="fetchUserItems"
            @closeCreateUserPopUp="closeCreateUserPopUp()"/>
        </div>
      </div>

      <AdminUsersTable
        @fetchUserItems="fetchUserItems"
        :loader="loader"/>

    </div>
  </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout"
import redirectUserByRole from "@/mixins/redirectUserByRole"
import {
  FETCH_USER_ITEMS,
} from "@/store/actions"
import {mapGetters} from "vuex"
import {
  RESET_VALIDATION_ERROR,
  RESET_USER_ITEM,
} from "@/store/mutations"
import AdminUserFilter from "@/components/dashboard/admin/users/AdminUserFilter"
import AdminCreateUserInfoPopUp from "@/components/basic/popups/AdminCreateUserInfoPopUp"
import AdminUsersTable from "@/components/dashboard/admin/users/AdminUsersTable"

export default {
  name: "AdminUsers",
  components:{
    AdminUsersTable,
    AdminCreateUserInfoPopUp,
    AdminUserFilter,
    UserDashboardLayout,
  },
  mixins: [
    redirectUserByRole,
  ],
  computed: {
    ...mapGetters([
      'userItems',
      'userItemsMeta',
      'userItemsFilter',
    ]),
    showTableFilter: function () {
      return !this.userItemsMeta.is_totally_empty
    },
  },
  async beforeRouteLeave (to, from, next) {
    await this.$store.commit(RESET_VALIDATION_ERROR)
    await this.$store.commit('resetUserItemsFiltersArr')
    next()
  },
  data(){
    return{
      filterComponentKey: null,
      createUserPopUpState: false,
      loader: false,
    }
  },
  mounted() {
    this.fetchUserItems()
  },
  methods:{
    async fetchUserItems(additionalData){
      this.loader = true
      await this.$store.dispatch(FETCH_USER_ITEMS, this.$helpers.prepareQueryFilterParams(this.userItemsFilter)).then(() => {
        //
      }).finally(() => {
        this.filterComponentKey = this.$helpers.uniqueId()
        this.loader = false
      })
    },
    filterUserItems(){
      this.userItemsFilter.page = 1
      this.fetchUserItems()
    },
    showCreateUserPopUp(){
      this.createUserPopUpState = true
    },
    closeCreateUserPopUp(){
      this.$store.commit(RESET_USER_ITEM)
      this.$store.commit(RESET_VALIDATION_ERROR)
      this.createUserPopUpState = false
    }
  }
}
</script>
