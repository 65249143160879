export default {
  data() {
    return {
      confirmActionPopUpState: false,
      confirmSecondActionPopUpState: false,
      confirmThirdActionPopUpState: false,
    }
  },
  methods: {
    async confirmAction(callbackAction) {
      await this[callbackAction]()
      this.confirmActionPopUpState = false
      this.confirmSecondActionPopUpState = false
      this.confirmThirdActionPopUpState = false
    },
  }
}
