<template>
  <div class="dropdown-list-container">

    <div class="add-dropdown-list-btn" @click="toggleDropdown()">

      <div class="dropdown-list-wrap" v-show="dropdownState">
        <slot></slot>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'DropdownList',
  data () {
    return {
      dropdownState: false
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  },
  methods: {
    toggleDropdown () {
      this.dropdownState = !this.dropdownState
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownState = false
      }
    }
  }
}
</script>
