<template>
  <div class="pagination-container" v-if="totalPages > 1">
    <button @click="changePage(1)" :disabled="currentPage === 1">1</button>
    <button
      @click="changePage(currentPage - maxPageNumbers)"
      :disabled="currentPage === 1 || totalPages <= maxPageNumbers"
      v-show="currentPage > maxPageNumbers"
    >&hellip;</button>
    <button
      v-for="pageNumber in displayedPageNumbers"
      :key="pageNumber"
      @click="changePage(pageNumber)"
      :disabled="currentPage === pageNumber"
      :class="{ active: currentPage === pageNumber }"
    >{{ pageNumber }}</button>
    <button
      @click="changePage(currentPage + maxPageNumbers)"
      :disabled="currentPage === totalPages || totalPages <= maxPageNumbers"
      v-show="totalPages > maxPageNumbers && currentPage < totalPages - maxPageNumbers + 1"
    >&hellip;</button>
    <button
      @click="changePage(totalPages)"
      :disabled="currentPage === totalPages"
    >{{ totalPages }}</button>
  </div>


<!--  <div class="pagination-container">-->
<!--    <button :disabled="currentPage === 1" @click="goToPage(currentPage - 1)">Back</button>-->
<!--    <span v-for="pageNumber in visiblePageNumbers" :key="pageNumber">-->
<!--      <button :class="{ active: pageNumber === currentPage }" @click="goToPage(pageNumber)">{{ pageNumber }}</button>-->
<!--    </span>-->
<!--    <button :disabled="currentPage === totalPages" @click="goToPage(currentPage + 1)">Next</button>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "ThePagination",
  props:[
    'currentPage',
    'totalPages',
    'maxPageNumbers',
  ],
  computed: {
    displayedPageNumbers() {
      let fromPageNumber = Math.max(2, this.currentPage - Math.floor(this.maxPageNumbers / 2))
      const toPageNumber = Math.min(this.totalPages - 1, this.currentPage + Math.floor(this.maxPageNumbers / 2))

      if (toPageNumber - fromPageNumber < this.maxPageNumbers - 2) {
        fromPageNumber = Math.max(2, toPageNumber - this.maxPageNumbers + 2)
      }

      const pageNumbers = []
      for (let i = fromPageNumber; i <= toPageNumber; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers
    }
  },
  methods: {
    changePage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages && pageNumber !== this.currentPage) {
        this.$emit('pageChanged', pageNumber)
      }
    }
  }
}
</script>
