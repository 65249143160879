<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="confirm-action-pop_up-block pop_up-container">
    <div class="pop_up-wrap">
      <div class="confirm-action-pop_up-container update_balance_pop-up">
        <div class="pop_up-info-fields-row replenish-balance-row">
          <div class="basic-form-wrap basic-input-wrap">
            <div class="basic-form-field-block">
              <input
                v-model="balance"
                :class="{ error: checkInputHasError('amount') }"
                type="number"
                placeholder="Enter the amount to replenish"
                id="balance">
              <label for="balance">Enter the amount to replenish</label>
            </div>
          </div>

          <ValidateErrors :fieldsErrors="validationErrors.errors"/>
        </div>
        <div class="confirm-action-pop_up-buttons">
          <button class="basic_green-btn" @click="updateBalance()">Save</button>
          <button class="reset_btn" @click="$emit('closePopUp')">Discard</button>
        </div>
      </div>
    </div>
  </div>



<!--  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="pop_up-container admin-info-pop_up-container">-->
<!--    <div class="pop_up-wrap scroll-container">-->
<!--      <div class="pop_up-info-row users-info-row">-->
<!--        <div class="pop_up-info-head users-info-head">-->
<!--          <div class="pop_up-info-head-left">-->
<!--            <button @click="updateBalance()" class="basic_green-btn">Save</button>-->
<!--          </div>-->
<!--          <div class="pop_up-info-head-right">-->
<!--            <button @click="$emit('closePopUp')" class="reset_btn">Close</button>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="pup_up-info-content">-->
<!--          <div class="pup_up-info-fields-row replenish-balance-row">-->
<!--            <div class="basic-form-wrap basic-input-wrap">-->
<!--              <div class="basic-form-field-block">-->
<!--                <input-->
<!--                  v-model="balance"-->
<!--                  :class="{ error: checkInputHasError('amount') }"-->
<!--                  type="number"-->
<!--                  placeholder="Enter the amount to replenish"-->
<!--                  id="balance">-->
<!--                <label for="balance">Enter the amount to replenish</label>-->
<!--              </div>-->
<!--            </div>-->

<!--            <ValidateErrors :fieldsErrors="validationErrors.errors"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
// import UserFieldsManage from "@/components/dashboard/admin/users/UserFieldsManage"
import checkWindowWidth from "@/mixins/checkWindowWidth"
import confirmAction from "@/mixins/confirmAction"
import {mapGetters} from "vuex"
import {UPDATE_USER_BALANCE, UPDATE_USER_ITEM} from "@/store/actions"
import ValidateErrors from "@/components/basic/ValidateErrors"
import checkInputHasError from "@/mixins/checkInputHasError"

export default {
  name: "AdminReplenishBalancePopUp",
  props:[
    'userID'
  ],
  components: {
    // UserFieldsManage,
    // ConfirmActionPopUp,
    ValidateErrors
  },
  mixins:[
    checkWindowWidth,
    confirmAction,
    checkInputHasError,
  ],
  computed: {
    ...mapGetters([
      'userFieldsArr',
      'validationErrors',
    ]),
  },
  data(){
    return{
      balance: null,
    }
  },
  methods:{
    async updateBalance(){
      await this.$store.dispatch(UPDATE_USER_BALANCE, {
        id: this.userID,
        params: {amount: this.balance}
      }).then(() => {
        if(!this.validationErrors.errors){
          this.$emit('closePopUp')
          this.$emit('fetchUserItems', this.userFieldsArr)
        }
      }).catch(() => {
        //
      }).finally(() => {
        //
      })
    },
  }
}
</script>
