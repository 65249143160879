<template>
  <div class="users-info-fields-row">
    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('first_name') }"
            v-model="userFieldsArr.first_name"
            type="text"
            placeholder="First name"
            id="user-info-first_name">
          <label for="user-info-first_name">First name</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('last_name') }"
            v-model="userFieldsArr.last_name"
            type="text"
            placeholder="Last name"
            id="user-info-last_name">
          <label for="user-info-last_name">Last name</label>
        </div>
      </div>
    </div>

    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('email') }"
            v-model="userFieldsArr.email"
            type="text"
            placeholder="Email"
            id="user-info-email">
          <label for="user-info-email">Email</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('phone') }"
            v-model="userFieldsArr.phone"
            type="text"
            placeholder="Phone"
            id="user-info-phone">
          <label for="user-info-phone">Phone</label>
        </div>
      </div>
    </div>
    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-select-wrap">
        <CountryField
          :disabled="!editState"
          id="country"
          v-model="userFieldsArr.country"/>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('city') }"
            v-model="userFieldsArr.city"
            type="text"
            placeholder="City"
            id="user-info-city">
          <label for="user-info-city">City</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('address') }"
            v-model="userFieldsArr.address"
            type="text"
            placeholder="Address"
            id="user-info-address">
          <label for="user-info-address">Address</label>
        </div>
      </div>
    </div>
    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('state') }"
            v-model="userFieldsArr.state"
            type="text"
            placeholder="State"
            id="user-info-state">
          <label for="user-info-state">State</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('postal_code') }"
            v-model="userFieldsArr.postal_code"
            type="text"
            placeholder="Postal code"
            id="user-info-postal_code">
          <label for="user-info-postal_code">Postal code</label>
        </div>
      </div>
    </div>
    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-select-wrap" :class="{ error: checkInputHasError('user_status') }">
        <vueSelect
          :disabled="!editState"
          v-model="userFieldsArr.user_status"
          label="text"
          :options="userStatusList"
          placeholder="User status"></vueSelect>
      </div>
      <div class="basic-select-wrap" :class="{ error: checkInputHasError('verification_status') }">
        <vueSelect
          :disabled="!editState"
          v-model="userFieldsArr.verification_status"
          label="text"
          :options="verificationStatusList"
          placeholder="Verification status"></vueSelect>
      </div>
    </div>

    <div class="admin-info-wrap users-info-wrap">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('password') }"
            v-model="userFieldsArr.password"
            type="password"
            autocomplete="new-password"
            placeholder="Password"
            id="user-info-password">
          <label for="user-info-password">Password</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('password') }"
            v-model="userFieldsArr.password_confirmation"
            type="password"
            autocomplete="new-password"
            placeholder="Password confirmation"
            id="user-info-password_confirmation">
          <label for="user-info-password_confirmation">Password confirmation</label>
        </div>
      </div>
    </div>

    <ValidateErrors :fieldsErrors="validationErrors.errors"/>
  </div>
</template>

<script>
import vueSelect from "vue-select"
import {mapGetters} from "vuex"
import checkInputHasError from "@/mixins/checkInputHasError"
import ValidateErrors from "@/components/basic/ValidateErrors"
import CountryField from "@/components/CountryField"

export default {
  name: "UserFieldsManage",
  components: {
    ValidateErrors,
    vueSelect,
    CountryField,
  },
  mixins:[
    checkInputHasError,
  ],
  props:[
    'editState'
  ],
  computed: {
    ...mapGetters([
      'userFieldsArr',
      'validationErrors',
    ]),
  },
  data(){
    return{
      userStatusList:['active', 'deleted'],
      verificationStatusList:['pending', 'verified'],
    }
  },
}
</script>
