<template>
  <div class="admin-table admin-users-table" :class="{full: userItemsMeta.last_page === 1}">
    <div class="admin-table-row admin-users-table-row">
      <p v-if="loader" class="loader"></p>
      <div class="table-head-row admin-users-container-table-wrap admin-users-table-head-row">
        <div class="table-line-wrap admin-users-table-line-wrap">
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-name-line">
            <span>Name</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-email-line">
            <span>Email</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-country-line">
            <span>Country</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-date-line">
            <span>Date</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-withdrawal-line">
            <span>Withdrawal</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-deposits-line">
            <span>Deposits</span>
          </div>
          <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-dropdown-line"></div>
        </div>
      </div>
      <div id="payments-table-main" class="admin-table-scroll-container admin-users-table-scroll-container scroll-container">
        <div v-if="userItems.length > 0" class="table-row table-content-row payments-table-row payments-table-content-row">
          <div
            class="table-line-wrap payments-table-line-wrap"
            :class="{ inactive: user.user_status === 'deleted' }"
            v-for="user in userItems"
            :key="user.id">
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-name-line">
              <span class="pointer" @click="showMoreInfo(user.id)">{{isEmptyField(user, 'first_name')}} {{isEmptyField(user, 'last_name')}}</span>
            </div>
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-email-line">
              <span class="pointer" @click="showMoreInfo(user.id)">{{isEmptyField(user, 'email')}}</span>
            </div>
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-country-line">
              <template v-if="user.country">
<!--                <img :src="getCountryFlagUrl(user.country)" alt=""/>-->
                <span>{{ getCountryName(user.country) }}</span>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-date-line">
              <span>Last visit: <strong>{{timestampToFormattedDate(user.last_login_at, false, '—')}}</strong></span>
              <span>Registered: <strong>{{timestampToFormattedDate(user.registered_at, false, '—')}}</strong></span>
            </div>
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-withdrawal-line">
              <span>Amount: <strong>{{isEmptyField(user, 'withdrawable_amount')}}</strong></span>
              <span>Completed: <strong>{{isEmptyField(user, 'completed_withdrawals_sum')}}</strong></span>
            </div>
            <div class="table-line table-content-line admin-users-table-line admin-users-table-content-line admin-users-table-deposits-line">
              <span>{{isEmptyField(user, 'active_deposits_sum')}}</span>
            </div>
            <div class="table-line table-head-line admin-users-table-line admin-users-table-head-line admin-users-table-dropdown-line">
              <div class="admin-users-table-actions">
                <template v-if="user.user_status === 'deleted'">
                  <div class="admin-users-action-btn" @click="prepareUserItemToPermanentlyDelete(user.id)" title="Delete permanently">
                    <v-icon class="rounded-circle">mdi-trash-can</v-icon>
                  </div>
                  <div class="admin-users-action-btn" @click="prepareUserItemToRestore(user.id)" title="Restore">
                    <v-icon class="rounded-circle">mdi-restore</v-icon>
                  </div>
                </template>
                <template v-else>
                  <div class="admin-users-action-btn" @click="prepareUserItemToDelete(user.id)" title="Delete">
                    <v-icon class="rounded-circle">mdi-trash-can-outline</v-icon>
                  </div>
                </template>

                <DropdownList>
                  <AdminUsersTableDropdown
                    :user="user"
                    @fetchUserItems="handleFetchUserItems"
                    @replenishBalance="showReplenishBalancePopUp(user.id)"
                    @showMoreInfo="showMoreInfo(user.id)"/>
                </DropdownList>
              </div>
            </div>
          </div>

          <AdminUserInfoPopUp
            @fetchUserItems="handleFetchUserItems"
            @closePopUp="closeUserInfo()"
            v-if="showMoreInfoPopUp"
            @closeUserInfo="closeUserInfo()"/>

          <AdminReplenishBalancePopUp
            :userID="userID"
            @fetchUserItems="handleFetchUserItems"
            @closePopUp="closeUserInfo()"
            v-if="showBalancePopUp"
          />
        </div>

        <div v-else class="no-result-container">
          <p>USERS TABLE IS EMPTY</p>
        </div>
      </div>
      <ThePagination
        v-if="userItemsMeta"
        :currentPage="userItemsMeta.current_page"
        :totalPages="userItemsMeta.last_page"
        :maxPageNumbers="5"
        @pageChanged="onPageChanged"/>

      <ConfirmActionPopUp
        v-if="confirmActionPopUpState && confirmDeleteActionPopUpState"
        description="Do you want delete this item?"
        @confirmAction="confirmAction('deleteUserItem')"
        @discardAction="closeActionPopup()"/>

      <ConfirmActionPopUp
        v-if="confirmActionPopUpState && confirmRestoreActionPopUpState"
        description="Do you want restore this item?"
        @confirmAction="confirmAction('restoreUserItem')"
        @discardAction="closeActionPopup()"/>

      <ConfirmActionPopUp
        v-if="confirmActionPopUpState && confirmPermanentlyDeleteActionPopUpState"
        description="Do you want permanently delete this item?"
        @confirmAction="confirmAction('permanentlyDeleteUserItem')"
        @discardAction="closeActionPopup()"/>
    </div>
  </div>
</template>

<script>
import DropdownList from "@/components/basic/DropdownList"
import ThePagination from "@/components/basic/ThePagination"
import {mapGetters} from "vuex"
import {
  DELETE_USER_ITEM,
  RESTORE_USER_ITEM,
  PERMANENTLY_DELETE_USER_ITEM,
  FETCH_USER_ITEM,
} from "@/store/actions"
import ConfirmActionPopUp from "@/components/basic/popups/ConfirmActionPopUp"
import confirmAction from "@/mixins/confirmAction"
import isEmptyField from "@/mixins/isEmptyField"
// import timeStampAndDateTransform from "@/mixins/timeStampAndDateTransform"
import {
  RESET_VALIDATION_ERROR,
  RESET_USER_ITEM,
} from "@/store/mutations"
import AdminUsersTableDropdown from "@/components/basic/dropdowns/AdminUsersTableDropdown"
import AdminUserInfoPopUp from "@/components/basic/popups/AdminUserInfoPopUp"
import gmtTimezones from "@/mixins/gmtTimezones"
import countryInfo from "@/mixins/countryInfo"
import AdminReplenishBalancePopUp from "@/components/basic/popups/AdminReplenishBalancePopUp"

export default {
  name: "AdminUsersTable",
  components: {
    AdminReplenishBalancePopUp,
    AdminUserInfoPopUp,
    AdminUsersTableDropdown,
    ConfirmActionPopUp,
    ThePagination,
    DropdownList,
  },
  props:[
    'loader'
  ],
  mixins:[
    confirmAction,
    isEmptyField,
    gmtTimezones,
    countryInfo,
    // timeStampAndDateTransform,
  ],
  data(){
    return{
      showMoreInfoPopUp: false,
      showBalancePopUp: false,
      userID: null,
      confirmDeleteActionPopUpState: false,
      confirmRestoreActionPopUpState: false,
      confirmPermanentlyDeleteActionPopUpState: false,
      // loader: false,
    }
  },
  computed: {
    ...mapGetters([
      'userItems',
      'userItemsMeta',
      'userItemsFilter',
    ]),
  },
  methods:{
    timestampToFormattedDate (timestamp, getUTC = false, placeholder = '') {
      if (!timestamp) return placeholder
      let newDate, dateString
      let year, month, date, hours, minutes
      newDate = this.setDateTimezone(new Date(timestamp * 1000))

      if (getUTC) {
        year = newDate.getUTCFullYear()
        month = newDate.getUTCMonth() + 1
        date = newDate.getUTCDate()
        hours = newDate.getUTCHours()
        minutes = newDate.getUTCMinutes()
      } else {
        year = newDate.getFullYear()
        month = newDate.getMonth() + 1
        date = newDate.getDate()
        hours = newDate.getHours()
        minutes = newDate.getMinutes()
      }

      dateString =
        ('0' + date).slice(-2) + '.' +
        ('0' + month).slice(-2) + '.' +
        year + ' ' +
        hours + ':' + minutes

      return dateString
    },
    prepareUserItemToDelete(id){
      this.userID = id
      this.confirmActionPopUpState = true
      this.confirmDeleteActionPopUpState = true
      this.confirmRestoreActionPopUpState = false
      this.confirmPermanentlyDeleteActionPopUpState = false
    },
    prepareUserItemToRestore(id){
      this.userID = id
      this.confirmActionPopUpState = true
      this.confirmDeleteActionPopUpState = false
      this.confirmRestoreActionPopUpState = true
      this.confirmPermanentlyDeleteActionPopUpState = false
    },
    prepareUserItemToPermanentlyDelete(id){
      this.userID = id
      this.confirmActionPopUpState = true
      this.confirmDeleteActionPopUpState = false
      this.confirmRestoreActionPopUpState = false
      this.confirmPermanentlyDeleteActionPopUpState = true
    },
    closeActionPopup(){
      this.userID = null
      this.confirmActionPopUpState = false
      this.confirmDeleteActionPopUpState = false
      this.confirmRestoreActionPopUpState = false
      this.confirmPermanentlyDeleteActionPopUpState = false
    },
    async deleteUserItem(){
      await this.$store.dispatch(DELETE_USER_ITEM, this.userID)
      this.$emit('fetchUserItems')
    },
    async restoreUserItem(){
      await this.$store.dispatch(RESTORE_USER_ITEM, this.userID)
      this.$emit('fetchUserItems')
    },
    async permanentlyDeleteUserItem(){
      await this.$store.dispatch(PERMANENTLY_DELETE_USER_ITEM, this.userID)
      this.$emit('fetchUserItems')
    },

    onPageChanged(pageNumber) {
      this.userItemsFilter.page = pageNumber
      this.$emit('fetchUserItems')
    },

    handleFetchUserItems(userItemFields) {
      this.$emit('fetchUserItems', userItemFields)
    },

    async showMoreInfo(id){
      await this.$store.dispatch(FETCH_USER_ITEM, id)
      this.showMoreInfoPopUp = true
    },
    showReplenishBalancePopUp(id){
      this.userID = id
      this.showBalancePopUp = true
    },
    closeUserInfo(){
      this.$store.commit(RESET_USER_ITEM)
      this.$store.commit(RESET_VALIDATION_ERROR)
      this.showMoreInfoPopUp = false
      this.showBalancePopUp = false
    }
  }
}
</script>
