import countriesList from "countries-list"

export default {
  data: function () {
    return {
      //
    }
  },
  methods: {
    getCountryName(iso2) {
      const countryInfo = countriesList.countries[iso2]

      return countryInfo ? countryInfo.name : ''
    },

    getCountryFlagUrl(iso2) {
      return `https://flagcdn.com/${iso2.toLowerCase()}.svg`
    }
  },
}
