<template>
  <ul>
<!--    <template v-if="user.user_status === 'deleted'">-->
<!--      <li @click="$emit('restoreUserItem')">-->
<!--        Restore-->
<!--      </li>-->
<!--      <li @click="$emit('permanentlyDeleteUserItem')">-->
<!--        Delete permanently-->
<!--      </li>-->
<!--    </template>-->
<!--    <template v-else>-->
<!--      <li @click="$emit('showMoreInfo')">-->
<!--        More Info-->
<!--      </li>-->
<!--      <li @click="$emit('replenishBalance')">-->
<!--        Balance-->
<!--      </li>-->
<!--      <li @click="$emit('deleteUserItem')">-->
<!--        Delete-->
<!--      </li>-->
<!--    </template>-->
    <template>
      <li @click="$emit('showMoreInfo')">
        More Info
      </li>
      <li @click="$emit('replenishBalance')">
        Balance
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: "AdminUsersTableDropdown",
  props: {
    user: Object,
  },
}
</script>
