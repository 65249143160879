<template>
  <div class="container-table-filter users-container-table-filter">

    <div class="container-table-filter-row">
      <div class="container-table-filter-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              v-model="userItemsFilter.full_name"
              type="text"
              placeholder="Name"
              id="users-filter-full_name">
            <label for="users-filter-full_name">Name</label>
          </div>
        </div>

        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              v-model="userItemsFilter.email"
              type="text"
              placeholder="Email"
              id="users-filter-email">
            <label for="users-filter-email">Email</label>
          </div>
        </div>
      </div>
      <div class="container-table-filter-wrap">
        <div class="basic-form-wrap basic-from-to-calendar-wrap">
          <DatePicker
            v-model="userItemsFilter.registered_from"
            mode="date"
            is24hr
            class="calendar-wrap calendar-wrap-from"
            :timezone="timezone"
            :locale="{ id: 'en-US', firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
            :popover="{ visibility: 'click' }"
            :max-date='new Date()'
            :model-config="modelConfig">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                placeholder="Registered From"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>

        <div class="basic-form-wrap basic-from-to-calendar-wrap">
          <DatePicker
            v-model="userItemsFilter.registered_to"
            mode="date"
            is24hr
            class="calendar-wrap calendar-wrap-from"
            :timezone="timezone"
            :locale="{ id: 'en-US', firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
            :popover="{ visibility: 'click' }"
            :max-date='new Date()'
            :min-date='userItemsFilter.registered_from'
            :model-config="modelConfig">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                placeholder="Registered To"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
      </div>
    </div>


    <div class="container-table-filter-btns users-container-table-filter-btns">
      <button
        class="basic_green-btn"
        @click="$emit('filterItems')">
        Search
      </button>
      <button
        class="reset_btn"
        @click="resetUserItemsFilter()">
        Reset
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import DatePicker from "v-calendar/lib/components/date-picker.umd"

export default {
  name: "AdminUserFilter",
  components:{
    DatePicker,
  },
  computed: {
    ...mapGetters([
      'userItemsFilter',
      'userItemsMeta',
    ]),
  },
  data(){
    return {
      timezone: '',
      modelConfig: {
        type: 'number',
        timeAdjust: '12:00:00',
      },
    }
  },
  mounted() {
    //
  },
  methods:{
    resetUserItemsFilter(){
      this.$store.commit('resetUserItemsFiltersArr')
      this.$emit('filterItems')
    },
  }
}
</script>
