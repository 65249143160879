<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="admin-info-pop_up-container pop_up-container user-info-pop_up-container">
    <div class="pop_up-wrap scroll-container">
      <div class="pop_up-info-row user-info-row">
        <div class="pop_up-info-head user-info-head">
          <div class="pop_up-info-head-left user-info-head-left">
            <button @click="createUser()" class="basic_green-btn">Create</button>
          </div>
          <div class="user-info-head-right">
            <button @click="$emit('closeCreateUserPopUp')" class="reset_btn">Close</button>
          </div>
        </div>

        <div class="user-info-content">
          <UserFieldsManage :editState="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkWindowWidth from "@/mixins/checkWindowWidth"
import {CREATE_USER_ITEM,} from "@/store/actions"
import {mapGetters} from "vuex"
import UserFieldsManage from "@/components/dashboard/admin/users/UserFieldsManage"

export default {
  name: "AdminCreateUserInfoPopUp",
  components: {
    UserFieldsManage,
  },
  mixins:[
    checkWindowWidth,
  ],
  computed: {
    ...mapGetters([
      'userFieldsArr',
      'validationErrors',
    ]),
  },
  methods:{
    async createUser(){
      let userFieldsArr = {
        first_name: this.userFieldsArr.first_name,
        last_name: this.userFieldsArr.last_name,
        email: this.userFieldsArr.email,
        password: this.userFieldsArr.password,
        password_confirmation: this.userFieldsArr.password_confirmation,
        phone: this.userFieldsArr.phone,
        address: this.userFieldsArr.address,
        city: this.userFieldsArr.city,
        state: this.userFieldsArr.state,
        postal_code: this.userFieldsArr.postal_code,
        country: this.userFieldsArr.country.iso2,
        user_status: this.userFieldsArr.user_status,
        verification_status: this.userFieldsArr.verification_status,
      }
      let additionalFetchInfo = userFieldsArr

      this.$store.dispatch(CREATE_USER_ITEM, userFieldsArr).then(() => {
        if(!this.validationErrors.errors){
          this.$emit('fetchUserItems', additionalFetchInfo)
          this.$emit('closeCreateUserPopUp')
        }
      }).catch(() => {
        //
      }).finally(() => {
        //
      })
    },
  }
}
</script>
