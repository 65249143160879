<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="pop_up-container admin-info-pop_up-container">
    <div class="pop_up-wrap scroll-container">
      <div class="pop_up-info-row users-info-row">
        <div class="pop_up-info-head users-info-head">
          <div class="pop_up-info-head-left users-info-head-left">
            <button @click="editUserItem(userFieldsArr.id)" class="basic_green-btn">
              <template v-if="editState">Save</template>
              <template v-else>Edit</template>
            </button>

<!--            <button @click="prepareUserItemToDelete(userFieldsArr.id)" class="reset_btn">Delete</button>-->

<!--            <ConfirmActionPopUp-->
<!--              v-if="confirmActionPopUpState"-->
<!--              description="Do you want delete this item?"-->
<!--              @confirmAction="confirmAction('deleteUserItem')"-->
<!--              @discardAction="confirmActionPopUpState = false"/>-->
          </div>
          <div class="user-info-head-right">
            <button @click="$emit('closeUserInfo')" class="reset_btn">Close</button>
          </div>
        </div>

        <div class="user-info-content">
          <UserFieldsManage :editState="editState"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkWindowWidth from "@/mixins/checkWindowWidth"
// import ConfirmActionPopUp from "@/components/basic/popups/ConfirmActionPopUp"
import confirmAction from "@/mixins/confirmAction"
import {
  DELETE_USER_ITEM,
  UPDATE_USER_ITEM,
} from "@/store/actions"
import {mapGetters} from "vuex"
import UserFieldsManage from "@/components/dashboard/admin/users/UserFieldsManage"

export default {
  name: "AdminUserInfoPopUp",
  components: {
    UserFieldsManage,
    // ConfirmActionPopUp,
  },
  mixins:[
    checkWindowWidth,
    confirmAction,
  ],
  computed: {
    ...mapGetters([
      'userFieldsArr',
      'validationErrors',
    ]),
  },
  data(){
    return{
      userID: null,
      editState: false,
    }
  },
  methods:{
    async editUserItem(id){
      if(this.editState){
        let userFieldsArr = this.userFieldsArr
        let additionalFetchInfo = userFieldsArr

        if (this.userFieldsArr.country) {
          userFieldsArr.country = this.userFieldsArr.country.iso2
        }

        if (this.userFieldsArr.password) {
          userFieldsArr.password = this.userFieldsArr.password
        } else {
          delete userFieldsArr.password
        }

        if (this.userFieldsArr.password_confirmation) {
          userFieldsArr.password_confirmation = this.userFieldsArr.password_confirmation
        } else {
          delete userFieldsArr.password_confirmation
        }

        this.$store.dispatch(UPDATE_USER_ITEM, {
          id: id,
          params: userFieldsArr
        }).then(() => {
          if(!this.validationErrors.errors){
            this.$emit('closePopUp')
            this.$emit('fetchUserItems', additionalFetchInfo)
          }
        }).catch(() => {
          //
        }).finally(() => {
          //
        })
      } else {
        this.editState = true
      }
    },
    // prepareUserItemToDelete(id){
    //   this.userID = id
    //   this.confirmActionPopUpState = true
    // },
    // async deleteUserItem(){
    //   await this.$store.dispatch(DELETE_USER_ITEM, this.userID)
    //   this.$emit('closePopUp')
    //   this.$emit('fetchUserItems')
    // },
  }
}
</script>
